<template>
	<GenreTab @changeGenre="changeGenre"></GenreTab>
	<!-- <div class="line"></div> -->
	<div class="sub_list_box_wrap" v-if="itemList.length > 0">
		<div class="sub_list_box" v-for="(item, index) in itemList" :key="index">
			<div class="list_box_img_wrap">
				<img
					v-if="checkAdultUser(item)"
					class="cursor_pointer"
					@click="goContentDetailPage(item)"
					alt="Opus Thumbnail"
					:src="`${$image_host}/contents/${item.thumb_path}`"
				/>
				<img
					v-else
					class="cursor_pointer"
					@click="goContentDetailPage(item)"
					alt="Opus Thumbnail"
					src="@/assets/images/adult_thumbnail.png"
				/>

				<div class="tag_wrap">
					<div class="tags" v-if="item.hash_tag != null">
						<div class="tag" v-for="(tag, index) in item.hash_tag.split(',').slice(0, 2)" :key="index">
							{{ tag }}
						</div>
					</div>

					<div class="tag"><i class="bx bxs-heart"></i>{{ item.fav_cnt.toLocaleString() }}</div>
					<div class="tag2" v-if="item.evt_yn === 'Y'">이벤트중</div>
				</div>
			</div>
			<div class="list_box_text_wrap">
				<div class="play_img cursor_pointer" @click="goContentDetailPage(item)">
					<img alt="" src="@/assets/images/icon_play.png" />
				</div>
				<h4>{{ item.opus_title }}</h4>
				<div class="name_box_wrap">
					<ul>
						<li>
							작가 : <span>{{ item.exposure_author }}</span>
						</li>
						<li>
							성우 : <span>{{ item.exposure_voice_actor }}</span>
						</li>
					</ul>
				</div>
			</div>
		</div>
	</div>
	<div class="no_data" v-else>
		<span class="ic"></span>
		<span class="tit">Sorry</span>
		<p>데이터가 없습니다.</p>
	</div>
	<Pagination v-if="itemList.length > 0" @changePage="changePage" :page="page" :totalPage="totalPage"></Pagination>
</template>

<script setup>
import Pagination from '@/shared-components/Pagination.vue';
import { ListByNewStore } from '@/store/contentStore';
import { storeToRefs } from 'pinia';
import GenreTab from '@/shared-components/GenreTab.vue';
import goContentDetailPage from '@/common-methods/goContentDetailPage';
import { checkAdultUser } from '@/common-methods/checkAccount';

const store = ListByNewStore();
store.setGenre('ALL');
store.getSubNewItems();

const { itemList, genre, page, totalPage } = storeToRefs(store);

// 장르 탭 emit 함수
const changeGenre = (genre) => {
	// store.$patch({ page: 1, genre });
	store.setGenre(genre);
	store.getSubNewItems();
};

// 페이지네이션 emit 함수
const changePage = (page) => {
	store.setPage(page);
	store.getSubNewItems();
};
</script>

<style scoped>
@media screen and (min-width: 851px) {
	.sub_list_box_wrap {
		/* min-height: 975px; */
		min-height: 752px;
	}
}

/* @media screen and (min-width: 1201px) {
	.sub_list_box_wrap {
		min-height: 975px;
	}
} */
</style>
